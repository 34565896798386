<template>
  <div class="container" style="position:relative;">
    <el-image @click="go_pay()" :src="url"></el-image>


 
	
	<div id="pays" style="  z-index99999;position: absolute; top:66px;left:0; background-color:  ; width: 100%;height:100%;">
		<ul style="padding: 0;"> 
					
					<li class="lis"  @click="go_goods('牙刷')" >
						
					</li> 
					<li class="lis" @click="go_goods('耳机')"  >
						
					</li> 
					<li class="lis"   @click="go_goods('牙签')">
						
					</li> 
					<li class="lis"   @click="go_goods('湿巾')">
						
					</li> 
					
					
					
					<li class="lis"  @click="go_goods('浴巾')" >
						
					</li> 
					<li class="lis"  @click="go_goods('牙膏')" >
						
					</li> 
					
					
					
					
					<li class="lis"  @click="go_goods('拖鞋')" >
						
					</li> 
					<li class="lis"  @click="go_goods('纸杯')" >
						
					</li> 
					
					
					
				</ul>
	 
	</div>
  </div>
</template>

<script>
  export default {
    name: "GoodsIndex",
    data() {
      return {
        url: require('../../assets/images/goods.jpg'),
		visibles:false
      }
    },
	methods: {
		go_goods(name){ 
			this.$router.push({name:name})	 
		},
		go_pay(){ 
		 this.$router.push({name: '商贸详情'})	 
		},
		close_pay(){
			this.visibles = false;
		},
	}
  }
</script>

<style scoped>
	
	.lis{
			 list-style-type:none; 
			 float: left; 
			 /* background-color: red; */
			 width: 260px; 
			 height:276px;
			 margin-right:36px;
			 margin-bottom: 30px;;
		}
		
	.container {
	  width: 100%;
	}
	
	.top {
	  position: relative;
	  width: 100%;
	}
	
	
  .container {
    width: 1200px;
    margin: auto;
  }
</style>
